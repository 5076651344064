import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import Icon from 'dpl/common/components/Icon';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import QuestionLayout from 'dpl/quiz/components/QuestionLayout';

import { TESTIMONIAL_INPUT_NAME } from '../../utils/constants';
import {
  getDisplayNameFromCurrentUser,
  getDogAge,
  findQuizAnswerByInputName
} from '../../utils';
import {
  testimonialQuizSubmissionAnswersDefinition,
  testimonialQuizEditPhotosDefinition
} from '../../utils/queryDefinitions';
import BuyerDisplay from '../../components/BuyerDisplay';
import Testimonial from '../../components/Testimonial';

export default function TestimonialReview({
  quizCreatedAt,
  onQuizSubmit,
  quizSubmissionId
}) {
  const { testimonialId } = useSelector(({ server }) => server);

  const { value } = useResourceQuery(
    testimonialQuizEditPhotosDefinition(testimonialId)
  );

  const {
    value: quizAnswers,
    isFetching,
    queryKey
  } = useResourceQuery(
    testimonialQuizSubmissionAnswersDefinition(quizSubmissionId)
  );

  const queryClient = useQueryClient();

  function invalidateTestimonialData() {
    queryClient.invalidateQueries(queryKey);
  }

  useEffect(() => {
    invalidateTestimonialData();
  }, []);

  const photographs = value.testimonial.photographs_attributes;

  const text = findQuizAnswerByInputName(
    quizAnswers,
    TESTIMONIAL_INPUT_NAME.EXPERIENCE
  );
  const dogName = findQuizAnswerByInputName(
    quizAnswers,
    TESTIMONIAL_INPUT_NAME.PUPPY_NAME
  );
  const instagramHandle = findQuizAnswerByInputName(
    quizAnswers,
    TESTIMONIAL_INPUT_NAME.INSTAGRAM
  );
  const birthDate =
    findQuizAnswerByInputName(quizAnswers, TESTIMONIAL_INPUT_NAME.BIRTH_DATE) ||
    value.testimonial?.dog_birth_date;
  const dogAge = getDogAge(birthDate, quizCreatedAt);

  const user = useCurrentUser().value.data;
  const userIsLoaded = Object.keys(user)?.length > 0;

  return (
    <div className="TestimonialReview">
      <div className="flex items-center vh-100">
        <LoadingWrapper isLoading={isFetching || !userIsLoaded}>
          {() => (
            <QuestionLayout
              title="Please review your update before sharing."
              subtitle="This may be shared with your breeder and displayed on their profile."
            >
              <div className="mb21 ba b--lightest-gray br3 ph4 pv4">
                <div className="pb2">
                  <BuyerDisplay
                    user={{
                      id: user.user_id,
                      initials: user.initials,
                      profile_photo_url: user.profile_photo_url
                    }}
                    displayName={getDisplayNameFromCurrentUser(user)}
                    date={quizCreatedAt}
                  />
                </div>
                <Testimonial
                  text={text}
                  photographs={photographs}
                  dogName={dogName}
                  instagramHandle={instagramHandle}
                  dogAge={dogAge}
                  clampText
                />
              </div>
              <div className="tc">
                <button
                  type="button"
                  onClick={onQuizSubmit}
                  className="button button--primary button--large pv3 inline-flex fw-medium mb8 items-center center"
                >
                  Submit update
                  <Icon className="ml1" name="fat-arrow-right" />
                </button>
              </div>
            </QuestionLayout>
          )}
        </LoadingWrapper>
      </div>
    </div>
  );
}

TestimonialReview.propTypes = {
  quizCreatedAt: PropTypes.string.isRequired,
  onQuizSubmit: PropTypes.func.isRequired,
  quizSubmissionId: PropTypes.number.isRequired
};
