import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { noop } from 'dpl/shared/utils';
import ImageUpload from 'dpl/components/Upload/ImageUpload';
import useFormContext from 'dpl/shared/form/hooks/useFormContext';
import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';
import useToastNotifications from 'dpl/shared/hooks/useToastNotifications';
import useUploadCapabilities from 'dpl/shared/hooks/useUploadCapabilities';
import SmartImage from 'dpl/components/SmartImage';
import Icon from 'dpl/common/components/Icon';

const IMAGE_WIDTH = '95px';
const IMAGE_HEIGHT = '142px';
function PhotoItem({ name, photoUrl }) {
  const formContext = useFormContext(name);

  function deletePhoto() {
    formContext.removeValue(name, formContext.formState);
  }

  return (
    <div className="PhotoItem relative dib pr-2 pt-3">
      <div className="overflow-hidden br2 br3-ns dib f0">
        <SmartImage
          Tag="div"
          width={IMAGE_WIDTH}
          height={IMAGE_HEIGHT}
          src={photoUrl}
          className="bg-center cover"
          alt="Upload"
        />
      </div>
      <button
        type="button"
        className="absolute right-0 top-0 mt-1 bg-dark-gray br-100 p-1 white"
        onClick={deletePhoto}
        title="Remove"
      >
        <Icon name="close" width="16px" height="16px" />
      </button>
    </div>
  );
}

PhotoItem.propTypes = {
  name: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired
};

function LoadingPhotoItem() {
  return (
    <div className="LoadingPhotoItem relative dib pr-2 pt-3">
      <div className="overflow-hidden br2 br3-ns dib f0">
        <div
          className="bg-loading-animation"
          style={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }}
        />
      </div>
    </div>
  );
}

function useMultiPhotoField(name, mediaCategoryId, options = {}) {
  const { limit = 10 } = options;
  const formState = useFormContext(name);
  const { pushToastNotification } = useToastNotifications();

  function addPhotoToFormState(uploadInstances) {
    const uploadData = uploadInstances.map(uploadInstance => ({
      url: uploadInstance.blob.scrappy_url,
      media_category_id: mediaCategoryId,
      file: uploadInstance.blob.signed_id
    }));

    formState.setValue(name, [...formState.formFieldValue, ...uploadData]);
  }

  function alertFailedPhotoUpload(err, uploads) {
    pushToastNotification({
      type: TOAST_NOTIFICATIONS_TYPES.ERROR,
      message: `Error uploading ${uploads.length} file${
        uploads.length === 1 ? '' : 's'
      }.`
    });
  }

  const { pendingUploads, uploadFiles } = useUploadCapabilities(
    addPhotoToFormState,
    alertFailedPhotoUpload
  );

  const photosFieldValue = Array.isArray(formState.formFieldValue)
    ? formState.formFieldValue
    : [];

  const photoItems = photosFieldValue.reduce((items, photo, idx) => {
    if (!photo._destroy) {
      items.push({
        photo,
        name: `${name}.${idx}`
      });
    }
    return items;
  }, []);

  const limitUploadFiles = files => {
    const allowedFiles = [...files].slice(0, limit - photoItems.length);
    if (files.length !== allowedFiles.length) {
      pushToastNotification({
        type: TOAST_NOTIFICATIONS_TYPES.ERROR,
        message: `Only ${limit} photos are allowed.`
      });
    }
    return uploadFiles(allowedFiles);
  };

  return {
    photoItems,
    uploadPhotos: limitUploadFiles,
    uploadIsPending: !!pendingUploads.length
  };
}

export default function MultiPhotoUpload({
  name,
  mediaCategoryId,
  onPendingStatusChange,
  limit
}) {
  const { photoItems, uploadPhotos, uploadIsPending } = useMultiPhotoField(
    name,
    mediaCategoryId,
    { limit }
  );

  const limitReached = photoItems.length === limit;

  useEffect(() => {
    onPendingStatusChange(uploadIsPending);
  }, [uploadIsPending]);

  return (
    <div className="MultiPhotoUpload">
      <div className="mb6 flex flex-wrap justify-center">
        {photoItems.map(({ photo, name: photoName }) => (
          <div key={photo.url} className="mr-1">
            <PhotoItem name={photoName} photoUrl={photo.url} />
          </div>
        ))}
        {uploadIsPending && <LoadingPhotoItem />}
      </div>
      <ImageUpload
        inlineTitle
        multiple
        name="photo_upload_grid_input"
        onChange={e => uploadPhotos(e.target.files)}
        title="Add photos"
        disabled={limitReached}
      />
      {limitReached && (
        <p className="black-50 mt2">You can only upload {limit} photos.</p>
      )}
    </div>
  );
}

MultiPhotoUpload.propTypes = {
  onPendingStatusChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  mediaCategoryId: PropTypes.number,
  limit: PropTypes.number
};

MultiPhotoUpload.defaultProps = {
  limit: 10,
  mediaCategoryId: null,
  onPendingStatusChange: noop
};
