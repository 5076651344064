import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import FormContext from 'dpl/shared/form/utils/context';
import useForm from 'dpl/shared/form/hooks/useForm';
import QuestionLayout from 'dpl/quiz/components/QuestionLayout';
import MultiPhotoUpload from 'dpl/form/components/MultiPhotoUpload';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import Icon from 'dpl/common/components/Icon';
import {
  testimonialQuizMutationDefinition,
  testimonialQuizEditPhotosDefinition
} from 'dpl/testimonials/utils/queryDefinitions';
import { isEmpty } from 'dpl/shared/utils/object';

/*
The TestimonialPhotoUpload component is under quiz however we are bypassing quiz logic here.
We are directly making a request to testimonials to update the
associated photographs and no quiz submission is necessary.
To bypass quiz formState, we have a nested formState within TestimonialPhotoUpload.
The MultiPhotoUpload's formContext will use this most recent formState.
*/

const name = 'photos';
export default function TestimonialPhotoUpload({
  title,
  subtitle,
  onNextClick
}) {
  const { testimonialId, mediaCategoryId } = useSelector(
    ({ server }) => server
  );

  const { isFetching, value } = useResourceQuery(
    testimonialQuizEditPhotosDefinition(testimonialId)
  );

  const { mutateAsync: updateTestimonial } = useResourceMutation(
    testimonialQuizMutationDefinition(testimonialId)
  );

  const { reset, setValue, formErrors, isSubmitting, formState, contextValue } =
    useForm({
      defaultFormState: { [name]: [] }
    });

  useEffect(() => {
    if (!isFetching) {
      setValue(name, value.testimonial.photographs_attributes);
    }
  }, [isFetching]);

  function handleNextClick() {
    const photosToUpdate = formState[name];

    updateTestimonial({
      body: {
        testimonial: {
          photographs_attributes: photosToUpdate
        }
      }
    }).then(reset);

    onNextClick();
  }

  const [isUploadPending, setIsUploadPending] = useState(false);

  return (
    <div className="TestimonialPhotoUpload min-h-100 flex flex-column justify-center items-center">
      <QuestionLayout title={title} subtitle={subtitle}>
        <div className="mb8">
          <FormContext.Provider value={contextValue}>
            <MultiPhotoUpload
              name={name}
              mediaCategoryId={mediaCategoryId}
              limit={8}
              onPendingStatusChange={setIsUploadPending}
            />
          </FormContext.Provider>
        </div>
        <div className="tc">
          <button
            type="button"
            onClick={handleNextClick}
            className="button button--primary button--large pv3 inline-flex fw-medium items-center"
            disabled={isSubmitting || !isEmpty(formErrors) || isUploadPending}
          >
            Continue
            <Icon className="ml1" name="fat-arrow-right" />
          </button>
        </div>
      </QuestionLayout>
    </div>
  );
}

TestimonialPhotoUpload.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onNextClick: PropTypes.func.isRequired
};

TestimonialPhotoUpload.defaultProps = {
  subtitle: ''
};
