import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

export default function MoreImagesCount({ count }) {
  return (
    <div className="MoreImagesCount pv1 ph2 inline-flex f2 br3 bg-white-80 dark-gray items-center">
      <div className="dn db-ns">
        <Icon name="camera-16" width="16px" height="16px" className="mr1" />
      </div>
      <span className="dn-ns">+</span>
      <span>{count}</span>
    </div>
  );
}

MoreImagesCount.propTypes = {
  count: PropTypes.number.isRequired
};
