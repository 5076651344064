import React, { Component } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_PROPNAME = 'isOn';

export default function withToggle({ propName = DEFAULT_PROPNAME } = {}) {
  const propNames = Array.isArray(propName) ? propName : [propName];
  const hasOnePropName = propNames.length === 1;

  return WrappedComponent =>
    class ToggledComponent extends Component {
      static propTypes = {
        ...propNames.reduce(
          (acc, pn) => ({ ...acc, [pn]: PropTypes.bool }),
          {}
        ),
        onToggle: PropTypes.func
      };

      static defaultProps = {
        ...propNames.reduce((acc, pn) => ({ ...acc, [pn]: null }), {}),
        onToggle: () => {}
      };

      static WrappedComponent = WrappedComponent;

      state = propNames.reduce(
        (acc, pn) => ({
          ...acc,
          [pn]: this.props[pn] || false
        }),
        {}
      );

      toggle = (isOn, togglePropName) => {
        if (typeof togglePropName !== 'string' && hasOnePropName) {
          [togglePropName] = propNames;
        } else if (!propNames.includes(togglePropName)) {
          throw new Error(
            `toggle called with ambiguous propName ${togglePropName}, should be one of ${propNames}`
          );
        }

        if (typeof isOn !== 'boolean') {
          isOn = !this.state[togglePropName];
        }

        if (isOn !== this.state[togglePropName]) {
          this.setState({ [togglePropName]: isOn });
          this.props.onToggle(isOn, togglePropName);
        }
      };

      toggleOn = togglePropName => this.toggle(true, togglePropName);

      toggleOff = togglePropName => this.toggle(false, togglePropName);

      render() {
        return (
          <WrappedComponent
            {...this.props}
            {...this.state}
            toggle={this.toggle}
            toggleOn={this.toggleOn}
            toggleOff={this.toggleOff}
          />
        );
      }
    };
}
