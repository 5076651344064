import { QUERY_KEY_PREFIXES } from 'dpl/shared/fetching/utils/constants';
import generateQueryKey from 'dpl/shared/fetching/utils/generateQueryKey';

export function testimonialQuizEditPhotosDefinition(testimonialId) {
  return {
    url: `/api/testimonials/${testimonialId}/edit_photos.json`,
    defaultValue: {
      testimonial: {
        photographs_attributes: []
      }
    }
  };
}

export function testimonialQuizEditDefinition(testimonialId) {
  return {
    prefix: QUERY_KEY_PREFIXES.TESTIMONIAL,
    url: `/api/testimonials/${testimonialId}/edit.json`,
    defaultValue: {
      testimonial: {}
    }
  };
}

export function testimonialQuizMutationDefinition(testimonialId) {
  return {
    url: `/api/testimonials/${testimonialId}`,
    method: 'PUT',
    refetchAfter: 'success',
    queryKey: generateQueryKey(
      testimonialQuizEditPhotosDefinition(testimonialId)
    )
  };
}

export function testimonialQuizSubmissionAnswersDefinition(quizSubmissionId) {
  return {
    url: `/api/testimonial_quiz_submission_answers/${quizSubmissionId}`,
    defaultValue: []
  };
}
