import { monthsBetweenDates } from 'dpl/shared/utils/date';
import { defaultGetSectionComponent } from 'dpl/quiz/utils';

import * as SECTION_COMPONENTS from '../sections/components';

export function getSectionComponent(section) {
  return (
    SECTION_COMPONENTS[section.component_name] ||
    defaultGetSectionComponent(section)
  );
}

export function getDogAge(puppyBirthDate, date) {
  if (!puppyBirthDate) return '';

  const birthDate = new Date(puppyBirthDate);
  const dateToUse = new Date(date || Date.now());
  const totalMonths = monthsBetweenDates(birthDate, dateToUse);

  const years = totalMonths >= 12 ? Math.floor(totalMonths / 12) : 0;
  const months = totalMonths % 12;

  const monthsText =
    months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';
  const yearsText = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';

  if (years > 0 && months === 0) {
    return `${yearsText} old`;
  } else if (years > 0 && months > 0) {
    return `${yearsText} ${monthsText} old`;
  } else if (months > 0) {
    return `${monthsText} old`;
  }
  return '';
}

/**
 * This logic recreates the testimonial's logic for rendering the public display name
 * of the current user (`buyer_display_name`). This function is used in the context
 * of the testimonial quiz because we do not yet have the testimonial object.
 * @param {{
 *   first_name: string;
 *   last_name: string;
 * }} currentUser The current user data object
 * @returns { string }
 */
export function getDisplayNameFromCurrentUser(currentUser) {
  return `${currentUser.first_name} ${currentUser.last_name[0]}.`;
}

export function findQuizAnswerByInputName(quizAnswers, inputName) {
  return quizAnswers?.find(q => q.input_name === inputName)?.quiz_answer;
}
