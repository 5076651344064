import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'dpl/common/components/Icon';
import TooltipWrapper from 'dpl/common/tooltip/components/TooltipWrapper';
import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';

function BuyerDisplay({ user, displayName, date, variant }) {
  const AVATAR_SIZE = isGreaterThanBreakpoint('sm') && 'lg';
  return (
    <div
      className={classNames('BuyerDisplay flex', {
        'flex-row items-center': variant === 'inline',
        'flex-column': variant === 'stacked'
      })}
    >
      <div className="mr4-sm mr3 mb2">
        <UserAvatar user={user} size={AVATAR_SIZE} />
      </div>
      <div>
        <div className="flex items-center">
          <p className="mr1 fw-medium font-16">{displayName}</p>{' '}
          <TooltipWrapper
            minWidth="230px"
            title="Verified by Good Dog. This buyer purchased their puppy using secure payments through the Good Dog platform."
          >
            <Icon name="verified" width="18px" height="18px" />
          </TooltipWrapper>
        </div>
        <p className="black-70 font-16">
          {new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric'
          })}
        </p>
      </div>
    </div>
  );
}

BuyerDisplay.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_photo_url: PropTypes.string,
    initials: PropTypes.string
  }).isRequired,
  displayName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['inline', 'stacked'])
};

BuyerDisplay.defaultProps = {
  variant: 'inline'
};

export default BuyerDisplay;
